<template>
    <div class="zt-page-content">
        <div v-if="type<=1" class="zt-block flex flex-justify-between" style="padding:20px 30px;">
            <div class="font14-grey1" style="font-size:16px">
                <span style="">支付方式：</span>
                <span v-if="type==0">线下支付</span>
                <span v-if="type==1">线上支付</span>
            </div>
            <div class="font14-grey1" style="font-size:16px">
                <span>应付金额：</span>
                <span v-if="order" style="color:#F66F6A;font-size:18px">{{order.amount | priceFilt}}</span>
            </div>
        </div>
        <div>
            <OfflinePay v-if="type==0 && order" :payCode="order"></OfflinePay>
            <OnlinePay v-if="type==1" :payCode="payCode" @onlineType="onlineType"></OnlinePay>
            <WxPay v-if="type==2" :payCode="payCode" @onlineType="onlineType"></WxPay>
        </div>
        <!-- <div class="zt-block">
            <div style="padding: 20px 30px;">
                <PaySucceed :payCode="payCode"></PaySucceed>
            </div>
        </div> -->
    </div>
</template>
<script>
    import { payId,getTransId } from '@/service/pay';
    import WxPay from '@/components/pay/WxPay.vue';
    import OfflinePay from '@/components/pay/OfflinePay.vue';
    import OnlinePay from '@/components/pay/OnlinePay.vue';
    // import ElsePay from '@/components/pay/ElsePay.vue';
    // import PaySucceed from '@/components/pay/PaySucceed.vue';
    export default {
        components: {
            OfflinePay,OnlinePay,WxPay
        },
        data() {
            return {
                activeName: 'first',
                payCode:{
                    // order_type:this.$route.query.oType,
                    // order_id:this.$route.query.order_id,
                    // url:this.$route.query.url,
                    // comp_id:this.$route.query.comp_id,
                    // amount:null,
                },
                type:0,// 0 线下支付，1 线上支付，2 微信支付
                order:null,//预支付订单信息
            }
        },
        async created() {
            this.type = this.$route.query.type || 0;
            this.get_transId();
            // this.payCode.amount = this.order.amount;
        },
        mounted() {},
        methods: {
            get_transId() {
                getTransId(this.$route.query.order_id).then(rst => {
                    this.order = rst;
                    console.log(this.order);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            back(path) {
                this.$router.go(-1);
            },
            onlineType(type) {
                console.log(type);
                this.type = type;
            },

        }
    }
</script>
<style scoped>
    .left>i {
        line-height: 40px;
        vertical-align: top;
        margin-right: 20px;
    }
    .right-top {
        font-size:18px;
        font-weight:bold;
        color:rgba(204,153,90,1);
        line-height:25px;
        vertical-align: top;
        margin-bottom: 15px;
    }
    .right-middle>div {
        font-size: 12px;
        color: #4D4D4D;
        line-height:20px;
        margin-right: 15px;
        margin-bottom: 26px;
    }
    .price {
        font-size:14px;
        font-weight:bold;
        color:rgba(41,41,41,1);
    }
</style>