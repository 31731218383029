<template>
    <div>
        <div class="zt-block">
            <div class="zt-block-head">快捷支付</div>
            <div style="padding:20px;">
                <div class="select-item" @click="select(idx)" :class="{'active':selectIdx===idx}" v-for="(item,idx) in list" :key="idx">
                    <div>
                        <span class="shPerson-radio">
                            <input type="radio" :checked="selectIdx===idx" name="radio" :id="idx" class="regular-radio"/>
                            <label :for="idx"></label>
                        </span>
                        <i class="iconfont icon-shouquan"></i>
                        <span>{{item.name}}</span>
                        <span style="margin-left:30px;color:#B3B3B3;">3个工作日完成转账</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="zt-block">
            <div style="padding:20px;" class="flex">
                <div class="flex-1"></div>
                <div style="font-size:24px;color:#F66F6A;margin-right:30px;">￥{{payCode.price | priceFilt}}</div>
                <div><el-button @click="wxpay()" type="primary" style="width:90px;">支付</el-button></div>
            </div>
        </div>
        
    </div>
</template>
<script>
    import _ from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { payPurOrder,offlinePay } from '@/service/pay';
    export default {
        components: {
            
        },
        props:['payCode'],
        data() {
            return {
                list:[
                    {name:"微信",priceType:0},
                    {name:"支付宝",priceType:1,},
                ],
                selectIdx:0,
            }
        },
        created() {
            this.comp = this.$store.state.loginUser.comp;
        },
        mounted() {},
        methods: {
            back() {
                this.$router.go(-1);
            },
            select(idx) {
                this.selectIdx = idx;
            },
            wxpay() {
                // this.$router.push("/sale/pur/wxPay");
                this.$emit("onlineType",2);
            },
            
        }
    }
</script>
<style scoped>
    .block {
        padding: 20px 30px;
    }
    .select-item {
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        font-size: 14px;
        color: #3B3B3B;
    }
    .select-item:hover {
        background: #F8F9FF;
    }
    .select-item.active {
        background: #F8F9FF;
    }
    .iconfont {
        font-size:20px;
        color:#25DB5A;
        padding:0 30px 0 5px;
        position: relative;
        top: 2px;;
    }
    /*---------------------radio框样式-----------------------*/
    .shPerson-radio {
        display: inline-block;
        /*width: 14px;*/
        /*height: 14px;*/
        cursor: pointer;
        position: relative;
        top: 5px;
        margin:0 10px 0 20px;
    }
    .regular-radio {
        display: none;
    }
    .regular-radio + label {
        -webkit-appearance: none;
        box-sizing: border-box;
        border: 1px solid #5074EE;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: block;
        position: relative;
    }
    .regular-radio:checked + label:after {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius:50%;
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 5px);
        background: #5074EE;
        box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
    }
    .regular-radio:checked + label {
        color: #99a1a7;
        border: 1px solid #5074EE;
        box-sizing: border-box;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1), inset 0px 0px 10px rgba(0,0,0,0.1);
    }
    .regular-radio + label:active, .regular-radio:checked + label:active {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }
</style>