<template>
    <div class="zt-block">
        <div class="zt-block-head flex">
            <div class="flex-1">交易流水号：1445677888999</div>
            <div>支付金额：<span style="color:#F66F6A;">￥{{payCode.price | priceFilt}}</span> 元</div>
        </div>
        <div style="text-align:center;">
            <div class="title">请打开微信扫描以下二维码完成支付</div>
            <div class="qrcode">
                <div v-if="total_times==0" @click="refresh()" class="masking">交易超时，请点击刷新二维码</div>
                <img width="300" height="300" :src="qrcodeUrl">
            </div>
            <div>距离二维码过期还剩 <span style="color:red;">{{total_times}}</span> 秒</div>
        </div>
    </div>
</template>
<script>
    import QRCode from 'qrcode';
    import { wxQrcode, wxQueryId } from '@/service/pay';
    const RSCODES = {
        'SUCCESS': '支付成功',
        'NOTPAY': '未支付',
        'CLOSED': '订单已关闭',
        'REVOKED': '已撤销（付款码支付）',
        'USERPAYING': '用户支付中（付款码支付）',
        'PAYERROR': '支付失败(其他原因，如银行返回失败)'
    }
    export default {
        components: {
        },
        props:['payCode'],
        data() {
            return {
                total_times:6,
                timeInterval:null,
                qrcodeUrl:null,
            }
        },
        filters:{
            price00(price) {
                var f_x = Math.round(price) / 100;
                var s_x = f_x.toString();
                var pos_decimal = s_x.indexOf('.');
                if (pos_decimal < 0) {
                    pos_decimal = s_x.length;
                    s_x += '.';
                }
                while (s_x.length <= pos_decimal + 2) {
                    s_x += '0';
                }
                return s_x;
            },
        },
        created() {
            this.cancel();
        },
        mounted() {},
        methods: {
            back(path) {
                this.$router.go(-1);
            },
            refresh() {
                // location.reload();
                this.cancel();
            },
            handleClick(tab, event) {
                console.log(tab.index);
            },
            showMsg(msg){
                // $('.qrcode').hide();
                // $('#msg').text(msg);
                // $('.msg').show();
                console.log(msg);
            },
            cancel(){
                this.total_times = 6;
                this.timeInterval = setInterval(() => {
                    this.total_times--;
                    if(this.total_times<=0){
                        clearInterval(this.timeInterval);
                    }else{
                        // $('#times').text(this.total_times);
                    }
                }, 1000);
                this.link = "http://demo.emergen.cn:8808";
                this.creatQRCode(this.link);
                // wxQrcode({order_no:this.orderId,amount:this.price}).then(rst => {
                //     console.log("wxQueryId",rst);
                //     this.link = "http://demo.emergen.cn:8808";
                // }).catch(err => {
                //     console.log(err);
                //     this.$message.error(err.message);
                // });
            },
            creatQRCode(link) {
                QRCode.toDataURL(link).then(url => {
                    this.qrcodeUrl = url;
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            query() {
                setTimeout(() => {
                    wxQueryId(this.orderId).then(rst => {
                        if (rst == 'SUCCESS') {
                            this.showMsg(RSCODES[rst]);
                            // alert('支付成功！');
                            this.$message.success("支付成功");
                            this.$router.push("/sale/pur/cgdd");
                        }
                        if (rst == 'NOTPAY') {
                            
                            if(this.total_times<=0){
                                // $('.time_tip').hide();
                                // $('.timeout').show();
                            }else{
                                this.query();
                            }
                        }
                        if (rst == 'CLOSED') {
                            this.showMsg(RSCODES[rst]);
                        }
                        if (rst == 'REVOKED') {
                            this.showMsg(RSCODES[rst]);
                        }
                        if (rst == 'USERPAYING') {
                            this.showMsg(RSCODES[rst]);
                        }
                        if (rst == 'PAYERROR') {
                            this.showMsg(RSCODES[rst]);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }, 2000);
            },
            goCgdd() {
                this.$router.push({
                    path:"/sale/pur/cgdd",
                });
            }
            
        }
    }
</script>
<style scoped>
    .block{
        margin: 20px;
        background: rgba(255,255,255,1);
        box-shadow: 0px 0px 4px 0px rgba(204, 204, 204, 1);
        padding: 20px 30px;
    }
    .frm {
        text-align:center;
    }
    .title {
        /* padding-bottom:20px; */
    }
    .qrcode {
        width:300px;
        margin:0 auto;
        position:relative;
        /* padding:30px 0; */
    }
    .qrcode .masking {
        width:80%;
        height:80%;
        background:rgba(0,0,0,0.5);
        text-align:center;
        line-height:224px;
        position:absolute;
        top:10%;
        left:10%;
        font-size:16px;
        color:#fff;
        cursor:pointer;
    }
</style>